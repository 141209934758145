/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import Login from '@pages/login/Login';
import Main from '@pages/main/Main';
import { handleLoginUserFromStorage } from '@store/slices/userSlice';
import { AppDispatch } from '@store/store';
import { handleCheckIfTokenIsExpired } from '@utils/utils';

import styles from './App.module.css';

function App() {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedUserEmail = localStorage.getItem('mail');
        const storedExp = localStorage.getItem('exp');
        const storedIat = localStorage.getItem('iat');
        const storedRole = localStorage.getItem('role');

        if (handleCheckIfTokenIsExpired()) {
            dispatch(handleLoginUserFromStorage({
                mail: storedUserEmail,
                token: storedToken,
                exp: storedExp,
                iat: storedIat,
                isLoggedIn: true,
                role: storedRole
            }));
        }

        // Check if the token is expired every 30s
        const interval = setInterval(() => {
            handleCheckIfTokenIsExpired();
        }, 1000 * 30);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div id={styles.layout}>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="*" element={<Main/>}/>
            </Routes>
        </div>
    );
}

export default App;
