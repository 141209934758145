/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback,useState } from 'react';

import { useApiCallInterface } from '@interfaces';
import { sendRequest } from '@utils/sendRequest';

export const useApiCall = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSendRequest = useCallback(async (requestConfig: useApiCallInterface) => {
        setIsLoading(true);
        const data = await sendRequest(requestConfig);
        setIsLoading(false);
        return data;
    }, []);

    return { isLoading, error, sendRequest: handleSendRequest };
};
