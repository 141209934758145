/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { handleLoginRequestParameters } from '@interfaces';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendRequest } from '@utils/sendRequest';

const initialState = {
    mail: null,
    token: null,
    isLoggedIn: false,
    exp: null,
    iat: null,
    role: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        handleLoginUserFromStorage: (state, action) => {
            state.mail = action.payload.mail;
            state.token = action.payload.token;
            state.exp = action.payload.exp;
            state.iat = action.payload.iat;
            state.isLoggedIn = action.payload.isLoggedIn;
            state.role = JSON.parse(action.payload.role);
        }
    },
    extraReducers: builder => {
        builder.addCase(handleLoginRequest.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                state.mail = action.payload.mail;
                state.token = action.payload.token;
                state.exp = action.payload.exp;
                state.iat = action.payload.iat;
                state.isLoggedIn = action.payload.success;
                state.role = action.payload.role;
                localStorage.setItem('token', action.payload.token);
                localStorage.setItem('mail', action.payload.mail);
                localStorage.setItem('exp', action.payload.exp);
                localStorage.setItem('iat', action.payload.iat);
                localStorage.setItem('role', JSON.stringify(action.payload.role));
            }
        });
        builder.addCase(handleLogoutRequest.fulfilled, (state) => {
            state.mail = null;
            state.token = null;
            state.exp = null;
            state.iat = null;
            state.role = null;
            state.isLoggedIn = false;
            localStorage.removeItem('token');
            localStorage.removeItem('mail');
            localStorage.removeItem('exp');
            localStorage.removeItem('iat');
            localStorage.removeItem('role');
        });
        builder.addCase(handleVerifyUser.fulfilled, (state, action) => {
            state.role = action.payload.role;
        });
    }
});

export const handleLoginRequest = createAsyncThunk(
    'user/handleLogin',
    async ({ mail, password }: handleLoginRequestParameters) => {
        try{
            const response = await sendRequest({
                url: '/account/login/',
                method: 'POST',
                authRequest: true,
                body: {
                    email: mail,
                    password: password
                }
            });
            if(response && response.token){
                response.success = true;
                response.mail = mail;
                return response;
            }
        } catch (e) {
            console.log(e);
        }
    }
);

export const handleLogoutRequest = createAsyncThunk(
    'user/handleLogout',
    async () => {
        const token = localStorage.getItem('token');
        if(token){
            try{
                await sendRequest({
                    url: '/account/logout/',
                    method: 'POST',
                    authRequest: true,
                    headers: { token: token }
                });
            } catch (e) {
                console.log(e);
            }
        }
    }
);

export const handleVerifyUser = createAsyncThunk(
    'user/handleVerifyUser',
    async () => {
        const token = localStorage.getItem('token');
        if(token){
            try{
                const response = await sendRequest({
                    url: '/account/verify/',
                    method: 'POST',
                    authRequest: true,
                    headers: { token: token }
                });
                if(response && response.role){
                    return response;
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
);

export const { handleLoginUserFromStorage } = userSlice.actions;
export default userSlice.reducer;