/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from '@components/card/Card';
import { dictionary } from '@dictionary';
import { storeInterface } from '@interfaces';
import { handleLogoutRequest } from '@store/slices/userSlice';
import { AppDispatch } from '@store/store';

import styles from './AboutMe.module.css';

function TopBar(props: {loginFunction: () => void, logoutFunction: () => void  }) {
    const user = useSelector((store: storeInterface) => store.user);
    return <>
        {
            user.isLoggedIn ?
                <div id={styles.logoutButtonContainer} onClick={props.logoutFunction}>
                    <p id={styles.logoutButton}>Logout</p>
                </div>
                :
                <div id={styles.loginButtonContainer} onClick={props.loginFunction}>
                    <p id={styles.loginButton}>Login</p>
                </div>
        }
    </>;
}

function AboutMe() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const handleClickOnLoginButton = () => {
        navigate('/login');
    };

    const handleClickOnLogoutButton = () => {
        dispatch(handleLogoutRequest());
    };

    return (
        <div id={styles.aboutMeSection}>
            <div id={styles.aboutMeSectionContent}>
                <TopBar loginFunction={handleClickOnLoginButton} logoutFunction={handleClickOnLogoutButton}/>
                <div id={styles.contentLayout}>
                    <div id={styles.textContainer}>
                        <p className={styles.text}>{dictionary.sections.aboutMe.welcomeText}</p>
                        <p className={styles.text} id={styles.secondText}>{dictionary.sections.aboutMe.welcomeText2}</p>
                        <a id={styles.cvDownloadButton} href='cv.pdf' download>{dictionary.sections.aboutMe.cvDownloadButton}</a>
                    </div>
                    <div>
                        <Card>
                            <img id={styles.aboutMePicture} src={'./pictures/picture.jpeg'} alt={'francois'}/>
                        </Card>
                    </div>
                </div>
            </div>
            <div id={styles.aboutMeSectionBottomDivider}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path style={{ fill: '#729EA1' }}
                        d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                        opacity=".25"></path>
                    <path style={{ fill: '#729EA1' }}
                        d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                        opacity=".5"></path>
                    <path style={{ fill: '#729EA1' }}
                        d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"></path>
                </svg>
            </div>
        </div>
    );
}

export default AboutMe;