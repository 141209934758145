/*
 * Copyright (C) 2023 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';

import Card from '@components/card/Card';
import Spinner from '@components/spinner/Spinner';
import Table from '@components/table/Table';
import { dictionary } from '@dictionary';
import { getApiUrl } from '@environment';
import { useApiCall } from '@hooks/useApiCall';
import { LearningTechnologiesStateInterface, storeInterface, technology } from '@interfaces';
import cache from '@utils/cache';
import {
    checkIfUserIsRole,
    processRawLearningTechnologies, processRawMasteredTechnologies
} from '@utils/utils';

import styles from './LearningTechnologies.module.css';

import AddLT from './overlays/AddLT';
import DeleteLT from './overlays/DeleteLT';

function LearningTechnologies() {
    const [addLTOverlay, setAddLTOverlay] = useState(false);
    const [deleteLTOverlay, setDeleteLTOverlay] = useState(false);

    const learningTechnologiesDisplayTimeout = 400;
    const [display3, setDisplay3] = useState(false);

    setTimeout(() => {
        setDisplay3(() => true);
    }, learningTechnologiesDisplayTimeout);

    const user = useSelector((store: storeInterface) => store.user);

    const { isLoading: areLearningTechnologiesLoading, sendRequest: fetchLearningTechnologies } = useApiCall();

    const [learningTechnologiesState, setLearningTechnologiesState] = useState<LearningTechnologiesStateInterface>({
        rawLearningTechnologies: [],
        processedLearningTechnologies: [{
            title: '',
            items: []
        }]
    });

    const handleFetchLearningTechnologies = useCallback(async (useCache: boolean) => {
        const ltUrl = getApiUrl() + '/technologies';

        if(cache.get(ltUrl) && useCache){
            const rawResponse = cache.get(ltUrl);
            const response = processRawLearningTechnologies(rawResponse.filter((technology: technology) => technology.status === 'learning'));
            setLearningTechnologiesState({
                rawLearningTechnologies: rawResponse,
                processedLearningTechnologies: response
            });
        } else {
            const rawResponse = await fetchLearningTechnologies({
                url: '/technologies',
                method: 'GET',
                dataTransformer: processRawLearningTechnologies,
                authRequest: false
            });

            if(rawResponse){
                const response = processRawLearningTechnologies(rawResponse.filter((technology: technology) => technology.status === 'learning'));
                cache.put(ltUrl, rawResponse);
                setLearningTechnologiesState({
                    rawLearningTechnologies: rawResponse,
                    processedLearningTechnologies: response
                });
            }
        }
    }, [getApiUrl, cache, processRawLearningTechnologies, setLearningTechnologiesState, fetchLearningTechnologies]);

    useEffect(() => {
        handleFetchLearningTechnologies(true).then(()=>{});
    }, [handleFetchLearningTechnologies]);

    return (
        <div id={styles.learningTechnologiesContainer}>
            <Transition in={display3} timeout={learningTechnologiesDisplayTimeout}>
                {state => (
                    <div id={styles.learningTechnologiesTableContainer} style={{ transition: 'opacity '+learningTechnologiesDisplayTimeout+'ms ease-out', opacity: state === 'entered' ? 1 : 0 }}>
                        <Card title={dictionary.sections.technologies.learningTechnologies}>
                            {
                                areLearningTechnologiesLoading
                                    ?
                                    <Spinner/>
                                    :
                                    <div className={styles.table}>
                                        <Table data={learningTechnologiesState.processedLearningTechnologies}
                                            sortByName={false}>
                                            {
                                                user.isLoggedIn && checkIfUserIsRole(user.role, dictionary.roles.admin) ?
                                                    <div className={styles.adminButtons}>
                                                        <button className={styles.technologiesTablesActionButton} onClick={async () => {
                                                            await handleFetchLearningTechnologies(false);
                                                        }}>
                                                            {dictionary.sections.technologies.adminButton.masteredTechnologies.refresh}
                                                        </button>
                                                        <button className={styles.technologiesTablesActionButton} onClick={() => {
                                                            setAddLTOverlay(() => true);
                                                        }}>
                                                            {dictionary.sections.technologies.adminButton.masteredTechnologies.add}
                                                        </button>
                                                        <button className={styles.technologiesTablesActionButton} onClick={() => {
                                                            setDeleteLTOverlay(() => true);
                                                        }}>
                                                            {dictionary.sections.technologies.adminButton.masteredTechnologies.delete}
                                                        </button>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                        </Table>
                                    </div>
                            }
                        </Card>
                    </div>
                )}
            </Transition>
            <div id={styles.projectSectionDivider}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                    preserveAspectRatio="none">
                    <path style={{ fill: '#729EA1' }} d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                </svg>
            </div>
            <AddLT handleClose={() => setAddLTOverlay(false)} open={addLTOverlay} handleFetchLearningTechnologies={handleFetchLearningTechnologies}/>
            <DeleteLT
                handleClose={() => setDeleteLTOverlay(false)}
                open={deleteLTOverlay}
                handleFetchLearningTechnologies={handleFetchLearningTechnologies}
                options={learningTechnologiesState.rawLearningTechnologies.map((rawTechnology) => rawTechnology.name)}
            />
        </div>
    );
}

export default LearningTechnologies;