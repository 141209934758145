/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import Card from '@components/card/Card';
import FooterIcon from '@components/footerIcon/FooterIcon';
import { dictionary } from '@dictionary';

import styles from './Footer.module.css';

function Footer() {
    return (
        <div id={styles.footerContainer}>
            <div className={styles.spacer} style={{ backgroundImage: 'url(footer.svg)' }}>
                <div id={styles.footerContentContainer}>
                    <Card>
                        <FooterIcon url={dictionary.linksURL.linkedin} logo={dictionary.LinksLogoURL.linkedin}/>
                        <FooterIcon url={dictionary.linksURL.gitlab} logo={dictionary.LinksLogoURL.gitlab}/>
                        <FooterIcon url={dictionary.linksURL.mail} logo={dictionary.LinksLogoURL.mail}/>
                    </Card>
                </div>
                <p id={styles.copyright}>&copy; 2020 - {new Date().getFullYear()} francoisdevilez.eu</p>
            </div>
        </div>
    );
}

export default Footer;