/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from 'react';

import styles from './Spinner.module.css';

function Spinner() {
    const [state, setState] = useState({
        loading: true
    });

    setTimeout(() => {
        setState({
            loading: false
        });
    }, 10000);

    return (
        <div className={styles.loaderContainer}>
            {state.loading ?
                <div className={styles.loader}/>
                :
                <p>
                    An error occured while fetching the data
                </p>}
        </div>
    );
}

export default Spinner;