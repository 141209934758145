/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { useSelector } from 'react-redux';

import { dictionary } from '@dictionary';
import { storeInterface } from '@interfaces';
import AboutMe from '@sections/aboutMe/AboutMe';
import Dashboard from '@sections/dashboard/Dashboard';
import Experiences from '@sections/experiences/Experiences';
import Footer from '@sections/footer/Footer';
import Projects from '@sections/projects/Projects';
import LearningTechnologies from '@sections/technologies/learning/LearningTechnologies';
import MasteredTechnologies from '@sections/technologies/mastered/MasteredTechnologies';
import { checkIfUserIsRole } from '@utils/utils';


function Main() {
    const user = useSelector((store: storeInterface) => store.user);
    
    return (
        <div>
            <AboutMe/>
            {
                user.isLoggedIn && checkIfUserIsRole(user.role, dictionary.roles.admin) ?
                    <Dashboard/>
                    :
                    <></>
            }
            <Projects/>
            <MasteredTechnologies/>
            <LearningTechnologies/>
            <Experiences/>
            <Footer/>
        </div>
    );
}

export default Main;