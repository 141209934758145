/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const cache = new Map();

// 1 minute alive cache
const maxAge = 60000;

const put = (url: string, res: Response) => {
    const entry = {
        url,
        res,
        lastRead: Date.now()
    };

    cache.set(url, entry);

    cache.forEach((cacheEntry) => {
        if(cacheEntry.lastRead < (Date.now() - maxAge)){
            cache.delete(cacheEntry.url);
        }
    });
};

const get = (url: string) => {
    const cached = cache.get(url);
    const tmp = (Date.now() - maxAge);
    if(!cached || cached.lastRead < tmp){
        return undefined;
    }

    return cached.res;
};

const isCacheEmpty = () => {
    return cache.size === 0;
};

const resetCache = () => {
    cache.clear();
};

export default { put, get, isCacheEmpty, resetCache };
