/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ChangeEvent, useState } from 'react';

import { inputField } from '@interfaces';

import styles from './useInputField.module.css';

const useInputField = (validationFunction: Function) => {
    const [userValue, setUserValue] = useState<inputField>({
        edited: false,
        valid: false,
        value: ''
    });

    const handleValueReset = () => {
        setUserValue({
            edited: false,
            valid: false,
            value: ''
        });
    };

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUserValue(() => {
            return {
                edited: true,
                valid: validationFunction(e.target.value),
                value: e.target.value
            };
        });
    };

    const buildInputFieldCssClasses = (value: inputField) => {
        let cssClass = styles.input + ' ';
        if(value.valid){
            cssClass = cssClass + styles.validInput;
        } else {
            cssClass = cssClass + styles.invalidInput;
        }
        return cssClass;
    };

    return {
        userValue,
        handleValueReset,
        handleValueChange,
        buildInputFieldCssClasses
    };
};

export default useInputField;
