/*
 * Copyright (C) 2023 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';

import Card from '@components/card/Card';
import Spinner from '@components/spinner/Spinner';
import Table from '@components/table/Table';
import { dictionary } from '@dictionary';
import { getApiUrl } from '@environment';
import { useApiCall } from '@hooks/useApiCall';
import { MasteredTechnologiesStateInterface, storeInterface, technology } from '@interfaces';
import cache from '@utils/cache';
import {
    checkIfUserIsRole,
    processRawMasteredTechnologies
} from '@utils/utils';

import styles from './MasteredTechnologies.module.css';

import AddMT from './overlays/AddMT';
import DeleteMT from './overlays/DeleteMT';

function MasteredTechnologies() {
    const [addMTOverlay, setAddMTOverlay] = useState(false);
    const [deleteMTOverlay, setDeleteMTOverlay] = useState(false);

    const masteredTechnologiesDisplayTimeout = 300;
    const [display2, setDisplay2] = useState(false);
    setTimeout(() => {
        setDisplay2(() => true);
    }, masteredTechnologiesDisplayTimeout);

    const user = useSelector((store: storeInterface) => store.user);

    const { isLoading: areMasteredTechnologiesLoading, sendRequest: fetchMasteredTechnologies } = useApiCall();

    const [masteredTechnologies, setMasteredTechnologies] = useState<MasteredTechnologiesStateInterface>({
        rawMasteredTechnologies: [],
        processedMasteredTechnologies: [{
            title: '',
            items: []
        }]
    });

    const handleFetchMasteredTechnologies = useCallback(async (useCache: boolean) => {
        const mtUrl = getApiUrl() + '/technologies';

        if(cache.get(mtUrl) && useCache){
            const rawResponse = cache.get(mtUrl);
            const response = processRawMasteredTechnologies(rawResponse.filter((technology: technology) => technology.status === 'mastered'));
            setMasteredTechnologies({
                rawMasteredTechnologies: rawResponse,
                processedMasteredTechnologies: response
            });
        } else {
            const rawResponse =  await fetchMasteredTechnologies({
                url: '/technologies',
                method: 'GET',
                dataTransformer: processRawMasteredTechnologies,
                authRequest: false
            });
            if(rawResponse){
                const response = processRawMasteredTechnologies(rawResponse.filter((technology: technology) => technology.status === 'mastered'));
                cache.put(mtUrl, rawResponse);
                setMasteredTechnologies({
                    rawMasteredTechnologies: rawResponse,
                    processedMasteredTechnologies: response
                });
            }
        }
    }, [getApiUrl, cache, processRawMasteredTechnologies, setMasteredTechnologies, fetchMasteredTechnologies]);

    useEffect(() => {
        handleFetchMasteredTechnologies(true).then(()=>{});
    }, [handleFetchMasteredTechnologies]);

    return (
        <div id={styles.masteredTechnologiesContainer}>
            <Transition in={display2} timeout={masteredTechnologiesDisplayTimeout}>
                {state => (
                    <div id={styles.masteredTechnologiesTableContainer} style={{ transition: 'opacity '+masteredTechnologiesDisplayTimeout+'ms ease-out', opacity: state === 'entered' ? 1 : 0 }}>
                        <Card title={dictionary.sections.technologies.masteredTechnologies}>
                            {
                                areMasteredTechnologiesLoading
                                    ?
                                    <Spinner/>
                                    :
                                    <div className={styles.table}>
                                        <Table data={masteredTechnologies.processedMasteredTechnologies} sortByName={true}>
                                            {
                                                user.isLoggedIn && checkIfUserIsRole(user.role, dictionary.roles.admin) ?
                                                    <div className={styles.adminButtons}>
                                                        <button className={styles.technologiesTablesActionButton} onClick={async () => {
                                                            await handleFetchMasteredTechnologies(false);
                                                        }}>
                                                            {dictionary.sections.technologies.adminButton.masteredTechnologies.refresh}
                                                        </button>
                                                        <button className={styles.technologiesTablesActionButton} onClick={() => {
                                                            setAddMTOverlay(() => true);
                                                        }}>
                                                            {dictionary.sections.technologies.adminButton.masteredTechnologies.add}
                                                        </button>
                                                        <button className={styles.technologiesTablesActionButton} onClick={() => {
                                                            setDeleteMTOverlay(() => true);
                                                        }}>
                                                            {dictionary.sections.technologies.adminButton.masteredTechnologies.delete}
                                                        </button>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                        </Table>
                                    </div>
                            }
                        </Card>
                    </div>
                )}
            </Transition>
            <AddMT handleClose={() => setAddMTOverlay(false)} open={addMTOverlay} handleFetchMasteredTechnologies={handleFetchMasteredTechnologies}/>
            <DeleteMT
                handleClose={() => setDeleteMTOverlay(false)}
                open={deleteMTOverlay}
                handleFetchMasteredTechnologies={handleFetchMasteredTechnologies}
                options={masteredTechnologies.rawMasteredTechnologies.map((rawMasteredTechnology) => rawMasteredTechnology.name)}
            />
        </div>
    );
}

export default MasteredTechnologies;