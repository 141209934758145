/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';

import Card from '@components/card/Card';
import Spinner from '@components/spinner/Spinner';
import Table from '@components/table/Table';
import { dictionary } from '@dictionary';
import { getApiUrl } from '@environment';
import { useApiCall } from '@hooks/useApiCall';
import { jsonParsedExportedTechnologies, processedProjects, storeInterface } from '@interfaces';
import cache from '@utils/cache';
import { checkIfUserIsRole, processRawProjectData } from '@utils/utils';

import styles from './Projects.module.css';

function Projects() {
    let importTechnologiesFile: File | undefined;
    let file: File | undefined;

    const projectDisplayTimeout = 200;
    const [display, setDisplay] = useState(false);
    setTimeout(() => {
        setDisplay(() => true);
    }, projectDisplayTimeout);

    const [projectState, setProjectState] = useState<Array<processedProjects>>([]);

    const user = useSelector((store: storeInterface) => store.user);

    const { isLoading: areProjectsLoading, sendRequest: fetchProjects } = useApiCall();
    const { isLoading: getExportTechnologiesLoading, sendRequest: exportTechnologies } = useApiCall();
    const { isLoading: getImportTechnologiesLoading, sendRequest: importTechnologies } = useApiCall();
    const { isLoading: isLogoUploadLoading, sendRequest: uploadIcon } = useApiCall();

    const handleFetchProjects = useCallback(async (useCache: boolean) => {
        const apiUrl = getApiUrl() + '/repositories';
        if (cache.get(apiUrl) && useCache) {
            const rawResponse = cache.get(apiUrl);
            const response = processRawProjectData(rawResponse);
            setProjectState(response);
        } else {
            try {
                const rawResponse = await fetchProjects({
                    url: '/repositories',
                    method: 'GET',
                    dataTransformer: processRawProjectData,
                    authRequest: false
                });
                if (rawResponse) {
                    const response = processRawProjectData(rawResponse);
                    cache.put(apiUrl, rawResponse);
                    setProjectState(response);
                }
            } catch (e) {
                alert(e);
            }
        }
    }, [getApiUrl, cache, processRawProjectData, setProjectState, fetchProjects]);

    const handleExportTechnologies = useCallback(async () => {
        try {
            const response = await exportTechnologies({
                url: '/technologies/export',
                method: 'GET',
                authRequest: false,
                headers: {
                    'token': user.token,
                }
            });
            const blob = new Blob([JSON.stringify(response)]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const technologies = JSON.parse(await blob.text()) as Array<jsonParsedExportedTechnologies>;
            let numberOfTechnologies = 0;
            technologies.forEach(category => numberOfTechnologies += category.technologies.length);
            link.setAttribute('download', new Date().toLocaleString() + '_' + numberOfTechnologies + '_TECHNOLOGIES' + '.json',);
            document.body.appendChild(link);
            link.click();
            link.parentNode ? link!.parentNode.removeChild(link) : '';
        } catch (error) {
            alert(error);
        }
    }, [exportTechnologies, user]);

    const handleImportTechnologies = useCallback(async () => {
        try {
            const rawTechnologies = await importTechnologiesFile?.text();
            const parsedTechnologies = rawTechnologies ? JSON.parse(rawTechnologies) : {};
            await importTechnologies({
                url: '/technologies/import',
                method: 'POST',
                authRequest: false,
                headers: {
                    'token': user.token,
                    'Content-Type': 'application/json'
                },
                body: parsedTechnologies
            });
        } catch (error) {
            console.log(error);
        }
    }, [importTechnologies, user]);

    const handleImportTechnologiesFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length === 1) {
            importTechnologiesFile = e.target.files[0];
            if (importTechnologiesFile.type !== 'application/json') {
                importTechnologiesFile = undefined;
                e.target.files = null;
                e.target.value = '';
                alert('Wrong file type');
                return;
            }
            await handleImportTechnologies();
        } else {
            alert('Wrong number of files: ' + e.target.files?.length);
        }
    }, [handleImportTechnologies]);

    const handleUploadIcon = useCallback(async () => {
        if (!file) {
            alert('No file selected !');
            return;
        }
        try {
            const body = new FormData();
            body.append('logo', file);
            await uploadIcon({
                url: '/technologies/logo',
                method: 'POST',
                authRequest: false,
                headers: {
                    'token': user.token,
                    'Content-Type': 'multipart/form-data'
                },
                body: body
            });
        } catch (error) {
            alert(error);
        }
    }, [uploadIcon]);

    const handleFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length === 1) {
            file = e.target.files[0];
            if (file.type !== 'image/png') {
                file = undefined;
                e.target.files = null;
                e.target.value = '';
                alert('Wrong file type');
                return;
            }
            await handleUploadIcon();
        } else {
            alert('Wrong number of files: ' + e.target.files?.length);
        }
    }, [handleUploadIcon]);

    useEffect(() => {
        handleFetchProjects(true).then(() => {
        });
    }, [handleFetchProjects]);

    return (
        <div id={styles.projectContainer}>
            <Transition in={display} timeout={projectDisplayTimeout}>
                {state => (
                    <div style={{
                        transition: 'opacity ' + projectDisplayTimeout + 'ms ease-out',
                        opacity: state === 'entered' ? 1 : 0
                    }} id={styles.projectTableContainer}>
                        <Card title={dictionary.sections.projects.title}>
                            {
                                areProjectsLoading
                                    ?
                                    <Spinner/>
                                    :
                                    <div className={styles.table}>
                                        <Table data={projectState} sortByName={false}/>
                                    </div>
                            }
                        </Card>
                    </div>
                )}
            </Transition>
            {
                user.isLoggedIn && checkIfUserIsRole(user.role, dictionary.roles.admin) ?
                    <Card title={dictionary.sections.technologies.adminButton.commons.title}>
                        <div className={styles.adminButtons}>
                            {
                                getExportTechnologiesLoading ?
                                    <Spinner/>
                                    :
                                    <button className={styles.technologiesTablesActionButton} onClick={async () => {
                                        await handleExportTechnologies();
                                    }}>
                                        {dictionary.sections.technologies.adminButton.commons.exportTechnologies}
                                    </button>
                            }
                            {
                                getImportTechnologiesLoading ?
                                    <Spinner/>
                                    :
                                    <form className={styles.uploadLogoForm}>
                                        <div className={styles.uploadLogoInputWrapper}>
                                            <input id={styles.uploadLogoInput} type="file"
                                                onChange={handleImportTechnologiesFileChange}/>
                                            <button className={styles.technologiesTablesActionButton}
                                                onClick={async () => {
                                                    await handleImportTechnologies();
                                                }}>
                                                {dictionary.sections.technologies.adminButton.commons.importTechnologies}
                                            </button>
                                        </div>
                                    </form>
                            }
                            {
                                isLogoUploadLoading ?
                                    <Spinner/>
                                    :
                                    <form className={styles.uploadLogoForm}>
                                        <div className={styles.uploadLogoInputWrapper}>
                                            <input id={styles.uploadLogoInput} type="file" onChange={handleFileChange}/>
                                            <button className={styles.technologiesTablesActionButton}
                                                onClick={handleUploadIcon}>{dictionary.sections.technologies.adminButton.commons.uploadIcon}</button>
                                        </div>
                                    </form>
                            }

                        </div>
                    </Card>
                    :
                    <></>
            }
        </div>
    );
}

export default Projects;