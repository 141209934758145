/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import Card from '@components/card/Card';
import Portal from '@components/modal/Portal';
import { modalProps } from '@interfaces';

import styles from './Modal.module.css';

function Modal({ children, isOpen, handleClose, cardTitle }: modalProps) {
    if (!isOpen) return null;

    return (
        <Portal wrapperId={'portal-root'}>
            <div className={styles.modal}>
                <Card title={cardTitle} controls={<div className={styles.modalControls}>
                    <button onClick={handleClose} className={styles.closeBtn}>
                        <img className={styles.closeBtnImg} src={'./pictures/icons/close.png'} alt={'Close popup'}/>
                    </button>
                </div>}>
                    <div className={styles.modalContent}>
                        {children}
                    </div>
                </Card>
            </div>
        </Portal>
    );
}

export default Modal;