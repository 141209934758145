/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
    dashboardLogState,
    processedLogs,
    processedProjects, processedStatisticsForGraphs,
    processedTechnologies,
    rawLogsData, rawStatisticsData,
    technology
} from '@interfaces';
import { getColor } from '@utils/colorMapping';

export const sortArray = (array: Array<any>, key: string) => {
    array.sort((a, b) => {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
    });
};

export const convertIsoStringDateToDate = (dateToConvert: string) => {
    return new Date(Date.parse(dateToConvert)).toLocaleDateString();
};

export const validURL = (str: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);
};

export const moveItemInArray = (array: Array<any>, from: number, to: number) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
};

/**
 * Returns true if token is valid, false if not.
 * @return boolean
 */
export const handleCheckIfTokenIsExpired = () => {
    const exp = localStorage.getItem('exp');
    if(exp){
        const parsedExp = new Date(exp);
        const currentTime = new Date(Date.now());
        return parsedExp > currentTime;
    }
    return false;
};

export const checkIfUserIsRole = (roles: Array<string> | null, role: string) => {
    const tmp = new Set(roles);
    return tmp.has(role);
};

export const processRawProjectData = (rawData: Array<any>): Array<processedProjects> => {
    let data: Array<processedProjects> = [];
    if (rawData && rawData.length > 0) {
        // Sort the repository array based on the last activity
        sortArray(rawData, 'lastActivityAt');

        // Parse dates
        rawData = rawData.map((repository) => {
            return {
                ...repository,
                lastActivityAt: convertIsoStringDateToDate(repository.lastActivityAt),
                createdAt: convertIsoStringDateToDate(repository.createdAt)
            };
        });

        // Set all the column titles
        Object.keys(rawData[0]).forEach((key) => {
            data.push({
                title: key,
                items: []
            });
        });

        // Put the corresponding data into their columns
        data.forEach((dataCategory) => {
            rawData.forEach((rawProject) => {
                Object.keys(rawProject).forEach((rawProjectKey) => {
                    if (dataCategory.title === rawProjectKey) {
                        dataCategory.items.push({
                            text: rawProject[rawProjectKey]
                        });
                    }
                });
            });
        });

        // Process title camelcase to sentence
        data.forEach((dataCategory) => {
            dataCategory.title = dataCategory.title.replace(/^[a-z]|[A-Z]/g, function(v, i) {
                return i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase();
            });

        });

        // Change column order
        moveItemInArray(data, 5,7);
        moveItemInArray(data, 8,7);
    }

    return data;
};

const processTechnologies = (rawData: Array<technology>, sortAlphabetically: boolean): processedTechnologies[] => {
    let data: Array<processedTechnologies> = [];
    if (rawData && rawData.length > 0) {
        // Get all categories
        rawData.forEach((rawDataTechnology) => {
            const category = rawDataTechnology.category;
            if (data.length === 0) {
                data.push({
                    title: category ? category : undefined,
                    items: []
                });
            } else {
                let add = true;
                data.forEach((dataTechnology) => {
                    if (dataTechnology.title === category) {
                        add = false;
                    }
                });
                if (add) {
                    data.push({
                        title: category ? category : undefined,
                        items: []
                    });
                }
            }
        });

        // Put the corresponding data into their categories
        data.forEach((dataTechnology) => {
            const categoryTitle = dataTechnology.title;
            rawData.forEach((rawDataTechnology) => {
                if (rawDataTechnology.category === categoryTitle) {
                    dataTechnology.items.push({
                        text: rawDataTechnology.name,
                        icon: rawDataTechnology.icon,
                        link: rawDataTechnology.link
                    });
                }
            });
        });

        if(sortAlphabetically) {
            data.sort((a,b) => {
                if (a.title && b.title){
                    return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
                }
                return 0;
            });
        }
    }
    return data;
};

export const processRawLearningTechnologies = (rawData: Array<technology>): processedTechnologies[] => {
    return processTechnologies(rawData, false);
};

export const processRawMasteredTechnologies = (rawData: Array<technology>): processedTechnologies[] => {
    return processTechnologies(rawData, true);
};

export const processRawLogsData = (rawData: rawLogsData, pageSize: number): dashboardLogState => {
    const rawLogs = rawData.logs;
    let data: Array<processedLogs> = [];

    if(rawLogs && rawLogs.length > 0){
        // Set all the column titles
        Object.keys(rawLogs[0]).forEach((key) => {
            data.push({
                title: key,
                items: []
            });
        });

        // Put the corresponding data into their columns
        data.forEach((category) => {
            if(category.title !== 'ID'){
                rawLogs.forEach((rawLog) => {
                    (Object.keys(rawLog) as Array<keyof typeof rawLog>).forEach((rawLogKey) => {
                        if (category.title === rawLogKey) {
                            category.items.push({
                                text: rawLog[rawLogKey] as string
                            });
                        }
                    });
                });
            }
        });

        data.forEach((dataCategory) => {
            dataCategory.title = dataCategory.title.replace(/_/g, ' ');
        });
    }

    return {
        rawResponseData: rawData,
        processedLogs: data,
        totalNumberOfLogs: rawData.totalNumberOfLogs,
        numberOfPages: Math.ceil(rawData.totalNumberOfLogs/pageSize)
    };
};

export const processLogRepartitionData = (data: rawStatisticsData): processedStatisticsForGraphs => {
    const logsData = data.numberOfLogsPerProject.map((project) => project.total);
    const bgc = data.numberOfLogsPerProject.map((project) => getColor(project.projectName));
    const transformedData = [{
        data: logsData,
        backgroundColor: bgc,
        borderColor: bgc
    }];
    return {
        labels: data.numberOfLogsPerProject.map((project)=> project.projectName),
        datasets: transformedData
    };
};

export const processAverageLogsPerProjectData = (data: rawStatisticsData): processedStatisticsForGraphs => {
    const dt = data.averageNumberOfLogsPerProject.map((project) => project.average);
    const bgc = data.averageNumberOfLogsPerProject.map((project) => getColor(project.projectName));
    const transformedData = [{
        data: dt,
        backgroundColor: bgc,
        borderColor: bgc
    }];
    return {
        labels: data.averageNumberOfLogsPerProject.map((project)=> project.projectName),
        datasets: transformedData
    };
};

export const processAverageLogsPerDayData = (data: rawStatisticsData): processedStatisticsForGraphs => {
    const dt = data.numberOfLogsPerDay.map((day) => day.total);
    const bgc = data.averageNumberOfLogsPerProject.map((project) => getColor(project.projectName));
    const transformedData = [{
        data: dt,
        backgroundColor: bgc,
        borderColor: ['#729EA1']
    }];
    return {
        labels: data.numberOfLogsPerDay.map((day) => new Date(day.date).toDateString()),
        datasets: transformedData
    };
};