/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState } from 'react';

const usePagination = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 50;

    const handlePreviousPage = () => {
        if(currentPage > 0){
            setCurrentPage((currentValue) => {
                return currentValue-1;
            });
        }
        return currentPage;
    };

    const handleNextPage = (numberOfPages: number) => {
        if(currentPage < numberOfPages-1){
            setCurrentPage((currentValue)=>{
                return currentValue+1;
            });
        }
        return currentPage;
    };

    return { currentPage, handlePreviousPage, handleNextPage, pageSize };
};

export default usePagination;