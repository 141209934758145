/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const getColor = (projectName: string): string => {
    switch (projectName.toLowerCase()){
    case 'neptune':
        return '#8aa3ff';
    case 'moon':
        return '#b5b5b5';
    case 'mercury':
        return '#806f63';
    case 'sun':
        return '#fdff8a';
    case 'star':
        return '#fff6f0';
    case 'earth':
        return '#97ff8a';
    case 'database':
        return 'pink';
    case 'database2':
        return 'orange';
    case 'pwp-testware':
        return 'lightblue';
    default:
        return 'white';
    }
};